import React, { useContext } from 'react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import manufacturerCustomization from '../../helpers/manufacturerCustomization';
import AppContext from '../../context';

const CustomButton = ({ clickMethod, width, children, icon, fontSize }) => {
  const mid = useContext(AppContext);

  return (
    <Button
      onClick={clickMethod}
      className="shadow-none" // To prevent the default highlighting behavior, which is that button gets highlighted when clicked and remains highlighted until something else is clicked
      style={{
        // fontWeight: 'bold',
        border: 'none',
        fontSize: fontSize || '14px',
        fontWeight: 'bold',
        backgroundColor: manufacturerCustomization[mid].colors.buttonBackground,
        color: manufacturerCustomization[mid].colors.buttonText,
        borderRadius: !manufacturerCustomization[mid].buttonRadius && 0,
        width,
        whiteSpace: 'nowrap',
      }}
    >
      {children}
      {icon
        && (
          <FontAwesomeIcon
            style={{ marginLeft: 10 }}
            icon={icon}
          />
        )}
    </Button>
  );
};

export default CustomButton;
