import React from 'react';
import { Spinner } from 'reactstrap';
import CustomButton from '../customButton/customButtom';

const SpinnerButton = ({ clickMethod, width, shouldSpin, children, icon }) => (
  shouldSpin ? (
    <CustomButton
      disabled
      width={width} // To avoid button resize between spinner and text
    >
      {/* eslint-disable-next-line react/no-children-prop */}
      <Spinner size="sm" children={null} />
      {/* children={null} to avoid default text 'Loading...' */}
    </CustomButton>
  ) : (
    <CustomButton
      icon={icon}
      clickMethod={clickMethod}
      width={width} // To avoid button resize between spinner and text
    >
      {children}
    </CustomButton>
  )
);

export default SpinnerButton;
