import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { ID_COOKIE_MAX_AGE_DAYS } from './settings';
import './App.css';
import Logo from './components/logo/logo';
import OrderInfo from './components/orderInfo/orderInfo';
import OrderLines from './components/orderLines/orderLines';
import ProductMeasurements from './components/productMeasurements/productMeasurements'; /* Not a
  very low hanging fruit for lazy/Suspend - "pops" in when loaded - would need placeholder or
  other solution */
import Fdv from './components/fdv/fdv';
import manufacturerCustomization from './helpers/manufacturerCustomization';
import AppContext from './context';
import getOrderInfo from './api/services/getOrderInfo';
import i18n from './i18n';
import CustomError from './components/customError/customError';
import Footer from './components/footer/footer';
import ComplaintForm from './components/complaintForm/complaintForm';
import Spinner from './components/spinner/spinner';

function App() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingOrderInfoData, setLoadingOrderInfoData] = useState(true);
  const [orderInfoData, setOrderInfoData] = useState(null);
  const [orderInfoLoadError, setOrderInfoLoadError] = useState();

  const getFavIcon = () => document.getElementById('favicon');

  useEffect(() => {
    if (orderInfoData) {
      const favIcon = getFavIcon();
      favIcon.href = manufacturerCustomization[orderInfoData.mid].favIconUrl;
      document.title = manufacturerCustomization[orderInfoData.mid].title;
    }
    // eslint-disable-next-line no-irregular-whitespace
  }, [orderInfoData]);

  useEffect(() => {
    /* Using useEffect to make the code in it run only once (ran several times on page load
      otherwise) */
    let id = searchParams.get('id');
    if (id) {
      setSearchParams({}); // To avoid filling the url bar with a long random looking text
      // todo: cookie acceptance box
      Cookies.set('id', id, { expires: ID_COOKIE_MAX_AGE_DAYS });
    } else {
      id = Cookies.get('id');
    }
  }, [searchParams]);

  useEffect(() => {
    const getOrderInfoData = async () => {
      try {
        const res = await getOrderInfo(Cookies.get('id'));
        setOrderInfoData(res.data);
        setLoadingOrderInfoData(false);
      } catch (error) {
        console.log(error);
        setOrderInfoLoadError(error);
        setLoadingOrderInfoData(false);
      }
    };

    getOrderInfoData();
  }, []);

  useEffect(() => {
    if (orderInfoData) {
      i18n.changeLanguage(manufacturerCustomization[orderInfoData.mid].language);
    }
  }, [orderInfoData]);

  if (!Cookies.get('id')) {
    return (
      <CustomError heading={t('error.missingId')} />
    );
  }

  if (loadingOrderInfoData) {
    return (
      /* Do not want to show the page until the manufacturer/brand is known,
      due to possible manufacturer-specific customization */
      <div className="App-loader">
        <Spinner
          size="3x"
          color="#808080"
        />
      </div>
    );
  }

  if (orderInfoLoadError) {
    let description = null;
    let errorCode = null;
    try {
      errorCode = orderInfoLoadError.data.err;
      if (orderInfoLoadError.data.msg) {
        description = `${errorCode} - ${orderInfoLoadError.data.msg}`;
      } else if (orderInfoLoadError.data.msgKey) {
        description = `${errorCode} - ${orderInfoLoadError.data.msgKey}`;
      }
    } catch (error) {
      description = orderInfoLoadError;
    }
    return (
      <CustomError
        heading={t('error.couldNotGetOrderInfo')}
        description={description}
      />
    );
  }

  return (
    <div
      className="App"
      style={{
        backgroundColor: manufacturerCustomization[orderInfoData.mid].imageBackground
          ? '#d7d7d7'
          : manufacturerCustomization[orderInfoData.mid].colors.mainBackground,
        color: manufacturerCustomization[orderInfoData.mid].colors.tableText,
        minHeight: '100vh',
        backgroundImage: manufacturerCustomization[orderInfoData.mid].imageBackground && `url(${manufacturerCustomization[orderInfoData.mid].backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <AppContext.Provider value={orderInfoData.mid}>
        <div className="wrapper">
          <br />
          <Logo logoUrl={manufacturerCustomization[orderInfoData.mid].logoUrl} />
          <br />
          <br />
          <OrderInfo data={orderInfoData} />
          <br />
          {
            orderInfoData.hasReportMeasures && (
              <>
                <ProductMeasurements />
                <br />
              </>
            )
          }
          {
            orderInfoData.hasReportMaintenance && (
              <>
                <Fdv />
                <br />
              </>
            )
          }
          <OrderLines />
          <br />
          <ComplaintForm />
          <br />
        </div>
        <Footer mid={orderInfoData.mid} />
      </AppContext.Provider>
    </div>
  );
}

export default App;
