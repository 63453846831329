import React, { useState, lazy, Suspense } from 'react';
import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../customButton/customButtom';
import OLCol from './oLCol';
import GenerateDrawing from './generate-drawing';

const NobbInfoModal = lazy(() => import('./nobbInfoModal'));
/* To test with delay:
const NobbInfoModal = lazy(() => new Promise((resolve) => {
  setTimeout(() => resolve(import('./nobbInfoModal')), 3000);
}));
*/

const OrderLine = ({ data, loading }) => {
  const [nobbInfoModalOpen, setNobbInfoModalIsOpen] = useState(false);

  const nobbInfoModalToggle = () => {
    setNobbInfoModalIsOpen(!nobbInfoModalOpen);
  };

  const { t } = useTranslation();

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Row style={{ paddingTop: 30, paddingBottom: 30, fontWeight: 'normal', lineHeight: '2' }}>
        <OLCol
          colIx="0"
          loading={loading}
        >
          {data.quantity}
        </OLCol>
        <OLCol
          colIx="1"
          loading={loading}
        >
          {data.refTxt}
        </OLCol>
        <OLCol
          colIx="2"
          loading={loading}
        >
          <>
            {data.width > 0
              && (
                <div>
                  {t('width')}
                  :
                  {' '}
                  {data.width}
                </div>
              )}
            {data.height > 0
              && (
                <div>
                  {t('height')}
                  :
                  {' '}
                  {data.height}
                </div>
              )}
            <GenerateDrawing vNumber={data.vNumber} />
          </>
        </OLCol>
        <OLCol
          colIx="3"
          loading={loading}
          style={{
            whiteSpace: 'pre-wrap',
            overflowX: 'auto',
          }}
        >
          {data.description}
        </OLCol>
        <OLCol colIx="4" loading={loading}>
          {data.uValue > 0 && data.uValue}
        </OLCol>
        <OLCol colIx="5" loading={loading}>
          {data.squareMeters > 0 && (Math.round(data.squareMeters * 100) / 100).toFixed(2)}
        </OLCol>
        <>
          {/* Button should open NOBB info in new tab for <md, modal for >=md: */}
          <OLCol
            colIx="6"
            loading={loading}
            className="d-md-none"
            style={{ textAlign: 'center' }}
          >
            {' '}
            <CustomButton
              width={75}
              clickMethod={() => {
                window.open(`https://nobb.no/nobbnr/${data.nobb}`);
              }}
            >
              {t('show')}
            </CustomButton>
          </OLCol>
          <OLCol
            colIx="6"
            loading={loading}
            className="d-none d-md-block"
          >
            {' '}
            {/* Show only for >=md */}
            <CustomButton width={75} clickMethod={nobbInfoModalToggle} fontSize={14}>
              {t('show')}
            </CustomButton>
            {
              nobbInfoModalOpen && (
                <Suspense fallback={null}>
                  <NobbInfoModal
                    isOpen={nobbInfoModalOpen}
                    toggle={nobbInfoModalToggle}
                    nobbNumber={data.nobb}
                  />
                </Suspense>
              )
            }
          </OLCol>
        </>
      </Row>
    </div>
  );
};

export default OrderLine;
