import React from 'react';

import { useTranslation } from 'react-i18next';

import manufacturerCustomization from '../../helpers/manufacturerCustomization';

const Footer = ({ mid }) => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="text-center p-3" style={{ backgroundColor: manufacturerCustomization[mid].colors.footerColor, color: 'white' }}>
        <a
          href={manufacturerCustomization[mid].manufacturerUrl}
          style={{ color: manufacturerCustomization[mid].colors.footerTextColor || 'white', textDecoration: 'none' }}
        >
          {t(`${manufacturerCustomization[mid].name}.footerLink`)}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
