import React, { useContext } from 'react';

import { Row, Col, Container } from 'reactstrap';
import manufacturerCustomization from '../../helpers/manufacturerCustomization';
import AppContext from '../../context';

const InfoContainer = ({ header, text, button }) => {
  const mid = useContext(AppContext);

  return (
    <>
      <Container
        style={{
          textAlign: 'center',
          lineHeight: 3,
          padding: 10,
          fontWeight: 'bold',
          backgroundColor: manufacturerCustomization[mid].colors.tableBackground,
          color: manufacturerCustomization[mid].colors.tableText,
          borderBottom: '1px solid lightgrey',
        }}
      >
        <Row
          style={{ width: '100%' }}
        >
          <div style={{ textAlign: 'center' }}>
            {header}
          </div>
        </Row>
      </Container>
      {typeof text === 'string' ? (
        <Container
          style={{
            textAlign: 'left',
            lineHeight: 3,
            padding: 10,
            fontWeight: 'bold',
            backgroundColor: manufacturerCustomization[mid].colors.tableBackground,
            color: manufacturerCustomization[mid].colors.tableText,
          }}
        >
          <Row>
            <Col md={12} style={{ textAlign: 'center' }}>
              <p
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: '600px',
                  fontWeight: 'normal',
                  lineHeight: '2',
                }}
              >
                {text}
              </p>
              <div style={{ paddingBottom: 20 }}>
                {button}
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          style={{
            textAlign: 'left',
            lineHeight: 3,
            padding: 10,
            fontWeight: 'bold',
            backgroundColor: manufacturerCustomization[mid].colors.tableBackground,
            color: manufacturerCustomization[mid].colors.tableText,
          }}
        >
          {text}
          <div style={{ textAlign: 'center' }}>
            {button}
          </div>
        </Container>
      )}
    </>
  );
};

export default InfoContainer;
