const manufacturerCustomization = {
  default: {
    language: 'no',
    name: 'vindusbutikk',
    title: 'Vindusbutikk - ordreinfo',
    complaintUrl: 'https://www.google.com',
    buttonRadius: false,
    imageBackground: true,
    favIconUrl: 'https://img.icons8.com/ios-glyphs/30/000000/internet--v1.png',
    logoUrl: 'https://img.icons8.com/ios/100/000000/bream.png',
    manufacturerUrl: 'https://www.google.com',
    backgroundImageUrl: 'https://img.icons8.com/ios/100/000000/bream.png',
    colors: {
      mainBackground: 'inherit',
      footerColor: 'inherit',
      tableBackground: 'inherit',
      tableText: 'inherit',
      buttonBackground: 'blue',
      buttonText: 'white',
    },
  },
  '2f007c66-bb3c-4808-8589-a010f085a80d': {
    name: 'panora',
    complaintUrl: 'https://forms.octaos.com/panora',
    language: 'no',
    buttonRadius: false,
    imageBackground: true,
    title: 'Panora - Order info',
    favIconUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/2F007C66-BB3C-4808-8589-A010F085A80D/domains/897131E0-E663-4C9F-B8B5-08D65B6C40A0/favicona724db67-2cbc-4aa8-8592-a56d5b9ca6e5.png',
    logoUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/2F007C66-BB3C-4808-8589-A010F085A80D/domains/897131E0-E663-4C9F-B8B5-08D65B6C40A0/standardLogo6091adfe-2de5-454e-ba19-9773a0f9d2c5.png',
    manufacturerUrl: 'https://panora.no',
    backgroundImageUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/2F007C66-BB3C-4808-8589-A010F085A80D/domains/897131E0-E663-4C9F-B8B5-08D65B6C40A0/backgroundebce30aa-5eb1-4930-8a73-168054588138.jpeg',
    colors: {
      mainBackground: '#e9ecef',
      footerColor: '#e9ecef',
      footerTextColor: '#364350',
      tableBackground: '#ffffff',
      tableText: '#555555',
      buttonBackground: '#a48947',
      buttonText: 'white',
    },
  },
  '4d097a1c-18e9-4c81-961c-0f6ce13890c5': {
    name: 'natre',
    complaintUrl: 'https://forms.octaos.com/natre',
    language: 'no',
    buttonRadius: false,
    imageBackground: true,
    title: 'Natre - Order info',
    favIconUrl: 'https://natre.no/media/1058/favicon.jpg?width=32&height=32',
    logoUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/4D097A1C-18E9-4C81-961C-0F6CE13890C5/domains/5476182D-798B-465C-AF5E-89408E598C3E/standardLogoA283F72B-9AB2-4544-8574-A43FBEC5792E.png',
    manufacturerUrl: 'https://natre.no',
    backgroundImageUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/4D097A1C-18E9-4C81-961C-0F6CE13890C5/domains/5476182D-798B-465C-AF5E-89408E598C3E/backgroundCBA030F0-CC33-4265-84AA-C4A25A96288D.jpeg',
    colors: {
      mainBackground: '#0054a3',
      footerColor: '#0054a3',
      tableBackground: '#ffffff',
      tableText: '#555555',
      buttonBackground: '#f16221',
      buttonText: 'white',
    },
  },
  'b5c50d89-e64b-4cb4-ad23-5ca765118f6a': {
    name: 'uldal',
    complaintUrl: 'https://forms.octaos.com/uldal',
    language: 'no',
    buttonRadius: true,
    imageBackground: true,
    title: 'Uldal - Order info',
    favIconUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/B5C50D89-E64B-4CB4-AD23-5CA765118F6A/domains/539425D1-6D71-4135-F6C7-08D782F2A668/favicon788b85e5-ccdc-4cf6-a329-4da5222a033f.ico',
    logoUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/B5C50D89-E64B-4CB4-AD23-5CA765118F6A/domains/539425D1-6D71-4135-F6C7-08D782F2A668/standardLogoff6cc5fc-1b62-4986-8430-959ceda20c61.png',
    manufacturerUrl: 'https://uldal.no',
    backgroundImageUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/B5C50D89-E64B-4CB4-AD23-5CA765118F6A/domains/539425D1-6D71-4135-F6C7-08D782F2A668/backgroundD9AF7525-0005-449F-8E69-BE78977801BD.jpeg',
    colors: {
      mainBackground: '#e9ecef',
      footerColor: '#111',
      tableBackground: '#ffffff',
      tableText: '#555555',
      buttonBackground: '#e4253a',
      buttonText: 'white',
    },
  },
  '9ad6d3e2-3009-4102-8b55-d1e117d953e7': {
    name: 'lf',
    complaintUrl: 'https://forms.octaos.com/lyssand-frekhaug',
    language: 'no',
    buttonRadius: false,
    imageBackground: true,
    title: 'Lyssand Frekhaug - Order info',
    favIconUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/9AD6D3E2-3009-4102-8B55-D1E117D953E7/domains/A23B70E0-3CFE-401A-F349-08D6B441E89C/faviconaa47d5c4-67c9-49c2-8610-548689655142.png',
    logoUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/9AD6D3E2-3009-4102-8B55-D1E117D953E7/domains/A23B70E0-3CFE-401A-F349-08D6B441E89C/standardLogo069d1c20-c18d-49ba-a533-31d93c1f29dd.png',
    manufacturerUrl: 'https://www.inwido.com/',
    backgroundImageUrl: 'https://ossdataprod.blob.core.windows.net/manufacturers-configs/9AD6D3E2-3009-4102-8B55-D1E117D953E7/domains/A23B70E0-3CFE-401A-F349-08D6B441E89C/background8f8f9545-ab30-4057-97eb-1ba0c0eb7f25.jpeg',
    colors: {
      mainBackground: '#e9ecef',
      footerColor: '#e9ecef',
      footerTextColor: '#364350',
      tableBackground: '#ffffff',
      tableText: '#555555',
      buttonBackground: '#a48947',
      buttonText: 'white',
    },
  },
};

export default manufacturerCustomization;
