import React from 'react';
import { useTranslation } from 'react-i18next';

const OMITTED = 'OMITTED 9e8bd20b-4a7e-4ef7-8bf5-102229c59d28';

const CustomError = ({
  errorCode,
  heading = OMITTED,
  description,
  recommendedAction = OMITTED,
  contactInfoLink,
  contactInfoLinkText,
  contactInfoNoLinkText,
}) => {
  const { t } = useTranslation();

  const errorCodeNumber = parseInt(errorCode, 10);
  const headingToUse = heading === OMITTED ? t('error.somethingWentWrong') : heading;
  let recommendedActionToUse = '';
  if (recommendedAction === OMITTED) {
    // eslintrc.js "yoda: ['error', 'never', { exceptRange: true }]," did not prevent error
    // eslint-disable-next-line yoda
    if (!(400 <= errorCodeNumber < 500)) {
      recommendedActionToUse = t('recommendedAction.tryAgainLater');
    }
  } else {
    recommendedActionToUse = recommendedAction;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <center>
        <h1 style={{ color: '#5950B7' }}>{headingToUse}</h1>
        {description && <h3>{description}</h3>}
        <h4>{recommendedActionToUse}</h4>
        <br />
        {contactInfoLink && <a href={contactInfoLink}>{contactInfoLinkText}</a>}
        {contactInfoNoLinkText && <p>contactInfoNoLinkText</p>}
      </center>
    </div>
  );
};

export default CustomError;
