import React, { useState, useContext, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { Row, Col } from 'reactstrap';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import OrderLine from './components/orderLine'; /* Not a very low hanging fruit for lazy loading,
  because to look nice it would need placeholder equal to that in orderLine, which uses oLCol for
  placeholders */
import CustomButton from '../customButton/customButtom';
import './orderLines.css';
import manufacturerCustomization from '../../helpers/manufacturerCustomization';
import AppContext from '../../context';
import getOrderLines from '../../api/services/getOrderLines';
import InfoContainer from '../infoContainer/infoContainer';

const dummyOrderLines = [
  {
    salesType: '',
    salesNumber: '',
    refTxt: '',
    description: '',
    uValue: 0,
    squareMeters: 0,
    nobb: '',
  },
  {
    salesType: '',
    salesNumber: '',
    refTxt: '',
    description: '',
    uValue: 0,
    squareMeters: 0,
    nobb: '',
  },
  {
    salesType: '',
    salesNumber: '',
    refTxt: '',
    description: '',
    uValue: 0,
    squareMeters: 0,
    nobb: '',
  },
];

const OrderLines = () => {
  const [loadingOrderLinesData, setLoadingOrderLinesData] = useState(true);
  const [orderLinesData, setOrderLinesData] = useState(dummyOrderLines); /* dummyOrderLines is for
    when loadingOrderLinesData */
  const [showOrderLines, setShowOrderLines] = useState(false);

  const { t } = useTranslation();

  const orderLineRef = useRef(null);

  const mid = useContext(AppContext);

  useEffect(() => {
    const getOrderLinesData = async () => {
      const res = await getOrderLines(Cookies.get('id'));
      setOrderLinesData(res.data);
      setLoadingOrderLinesData(false);
    };

    if (showOrderLines && orderLinesData[0].salesNumber === '') {
      getOrderLinesData();
    }

    if (showOrderLines) {
      orderLineRef.current.scrollIntoView({ behavior: 'smooth' });
      // window.scrollTo(0, orderLineRef);
    }
  }, [showOrderLines]);

  const showOrderLinesToggle = () => {
    setShowOrderLines(!showOrderLines);
  };

  const Text = () => (
    <div>
      <Row>
        {!showOrderLines && (
          <Col md={12} style={{ textAlign: 'center' }}>
            <p
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '600px',
                fontWeight: 'normal',
                lineHeight: '2',
              }}
            >
              {t(`${manufacturerCustomization[mid].name}.orderLineText`)}
            </p>
          </Col>
        )}
      </Row>
      {
        showOrderLines && (
          <div>
            {orderLinesData.map((orderLineData, index) => (
              <React.Fragment key={index}>
                <div>
                  <OrderLine
                    data={orderLineData}
                    loading={loadingOrderLinesData}
                  />
                  {
                    index + 1 < orderLinesData.length && <hr />
                    /* Separator after every OrderLine except last */
                  }
                </div>
              </React.Fragment>
            ))}
          </div>
        )
      }
    </div>
  );

  const Button = () => (
    <CustomButton
      width={100}
      clickMethod={showOrderLinesToggle}
      icon={showOrderLines ? faAngleUp : faAngleDown}
    >
      {showOrderLines ? <span>{t('hide')}</span> : <span>{t('show')}</span>}
    </CustomButton>
  );

  return (
    <div ref={orderLineRef}>
      <InfoContainer
        header={t('orderLines')}
        text={<Text />}
        button={<Button />}
      />
    </div>
  );
};

export default OrderLines;
