import React, { useState, useContext, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../customButton/customButtom';
import OICol from './components/oICol';
import './orderInfo.css';
import manufacturerCustomization from '../../helpers/manufacturerCustomization';
import AppContext from '../../context';
import SpinnerButton from '../spinnerButton/spinnerButton';
import getHandleReportClick from '../../etc/getHandleReportClick';

const TrackingInfoModal = lazy(() => import('./components/trackingInfoModal/trackingInfoModal'));
/* To test with delay:
const TrackingInfoModal = lazy(() => new Promise((resolve) => {
  setTimeout(() => resolve(import('./components/trackingInfoModal/trackingInfoModal')), 3000);
}));
*/

const OrderInfo = ({ data }) => {
  const { t } = useTranslation();
  const [reportUrlLoading, setReportUrlLoading] = useState(false);
  const [trackingInfoModalOpen, setTrackingInfoModalOpen] = useState(false);
  const trackingInfoModalToggle = () => {
    setTrackingInfoModalOpen(!trackingInfoModalOpen);
  };
  const handleReportClick = getHandleReportClick('ORDERINFO_REPORT_UVALUE', setReportUrlLoading);
  const mid = useContext(AppContext);

  return (
    <>
      <Container
        style={{
          paddingTop: 15,
          paddingBottom: 15,
          backgroundColor: manufacturerCustomization[mid].colors.tableBackground,
          color: manufacturerCustomization[mid].colors.tableText,
        }}
      >
        <Row className="order-info-row d-flex align-items-center">
          <OICol
            colIx="0"
            style={{ fontWeight: 'bold' }}
          >
            {t(`${data.salesType}Number`)}
          </OICol>
          <OICol colIx="1">{data.salesNumber}</OICol>
        </Row>
        <hr />
        <Row className="order-info-row d-flex align-items-center">
          <OICol colIx="0">{t('totalArea')}</OICol>
          <OICol colIx="1">{data.area}</OICol>
        </Row>
        <hr />
        <Row className="order-info-row d-flex align-items-center">
          <OICol colIx="0">{t('meanUValue')}</OICol>
          <OICol colIx="1">{data.uValue}</OICol>
          <OICol colIx="2">
            <div className="right-aligned-btn-container">
              {
                data.hasReportUValue && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <SpinnerButton
                      icon={faExternalLinkAlt}
                      clickMethod={handleReportClick}
                      width="175px"
                      shouldSpin={reportUrlLoading}
                    >
                      {t('uValueAccounts')}
                    </SpinnerButton>
                  </Suspense>
                )
              }
            </div>
          </OICol>
        </Row>
        {/* <hr />
        <Row className="order-info-row d-flex align-items-center">
          <OICol colIx="0">{t('numberOfProducts')}</OICol>
          <OICol colIx="1">{data.productsQuantity}</OICol>
        </Row> */}
        <hr />
        <Row className="order-info-row d-flex align-items-center">
          <OICol colIx="0">{t('status')}</OICol>
          <OICol colIx="1">{t(`statusText.${data.status}`)}</OICol>
          <OICol colIx="2">
            <div className="right-aligned-btn-container">
              {
                data.hasTrackingInfo && (
                  <CustomButton clickMethod={trackingInfoModalToggle}>
                    {t('trackingInfo')}
                  </CustomButton>
                )
              }
              {
                trackingInfoModalOpen && (
                  <Suspense fallback={null}>
                    <TrackingInfoModal
                      isOpen={trackingInfoModalOpen}
                      toggle={trackingInfoModalToggle}
                    />
                  </Suspense>
                )
              }
            </div>
          </OICol>
        </Row>
      </Container>
    </>
  );
};

export default OrderInfo;
