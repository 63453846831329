/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col } from 'reactstrap';
import ReactPlaceholder from 'react-placeholder/lib';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useTranslation } from 'react-i18next';

const xss = [12, 12, 12, 12, 12, 12, 12];
const mds = [ // Grouping rows in 12 to visualize no. of cols in each row
  12, 12, 12, 12,
  12, 12, 12,
];
const lgs = [1, 1, 2, 4, 1, 1, 1, 1];

const OLCol = ({ colIx, bodyTextAlign, loading, placeholderType, ...colProps }) => {
  const { t } = useTranslation();

  const headings = [
    t('quantity'),
    t('reference'),
    t('product'),
    t('productDescription'),
    t('uValue'),
    t('area'),
    t('documentation'),
  ];

  return (
    <Col className="align-center-md col-padding-md" xs={xss[colIx]} md={mds[colIx]} lg={lgs[colIx]} {...colProps}>
      <div style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>
        {headings[colIx]}
      </div>
      <ReactPlaceholder
        type={placeholderType}
        rows={8}
        ready={!loading}
        showLoadingAnimation
      >
        <div style={{ textAlign: bodyTextAlign, paddingRight: 20 }}>
          {colProps.children}
        </div>
        <p />
      </ReactPlaceholder>
    </Col>
  );
};

export default OLCol;
