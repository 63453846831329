import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import getReportUrl from '../api/services/getReportUrl';

const getHandleReportClick = (report, setReportUrlLoading) => {
  /* report must be one of:
    'ORDERINFO_REPORT_MAINTENANCE'
    'ORDERINFO_REPORT_MEASURES'
    'ORDERINFO_REPORT_UVALUE'
  */
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const handleReportClick = async () => {
    let res = null;
    setReportUrlLoading(true);
    try {
      res = await getReportUrl(Cookies.get('id'), report);
    } catch (error) {
      addToast(
        <>
          {`${t('error.couldNotGetReport')}:`}
          <br />
          {`${error.data.err} - ${error.data.msg}`}
        </>,
        { appearance: 'error', autoDismiss: true },
      );
      setReportUrlLoading(false);
      return;
    }
    if (res.data.url !== null) {
      window.open(res.data.url);
    } else {
      addToast(
        <>
          {`${t('error.couldNotGetReport')}:`}
          <br />
          {t('error.somethingWentWrong')}
        </>,
        { appearance: 'error', autoDismiss: true },
      );
      setReportUrlLoading(false);
      return;
    }
    setReportUrlLoading(false);
  };
  return handleReportClick;
};

export default getHandleReportClick;
