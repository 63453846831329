/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col } from 'reactstrap';

const xss = [12, 12, 12];
const sms = [4, 3, 5];
const lgs = [2, 2, 8];

const OICol = (props) => {
  const { colIx, ...colProps } = props;
  return (
    <Col
      style={{ fontWeight: colIx === '0' && 'bold' }}
      xs={xss[colIx]}
      sm={sms[colIx]}
      lg={lgs[colIx]}
      {...colProps}
    />
  );
};

export default OICol;
