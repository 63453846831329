import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import manufacturerCustomization from '../../helpers/manufacturerCustomization';
import AppContext from '../../context';
import SpinnerButton from '../spinnerButton/spinnerButton';
import getHandleReportClick from '../../etc/getHandleReportClick';
import InfoContainer from '../infoContainer/infoContainer';

const Fdv = () => {
  const { t } = useTranslation();
  const mid = useContext(AppContext);
  const [reportUrlLoading, setReportUrlLoading] = useState(false);
  const handleReportClick = getHandleReportClick('ORDERINFO_REPORT_MAINTENANCE', setReportUrlLoading);

  const Button = () => (
    <SpinnerButton
      icon={faExternalLinkAlt}
      clickMethod={handleReportClick}
      width="200px"
      shouldSpin={reportUrlLoading}
    >
      {t('getReport')}
    </SpinnerButton>
  );

  return (
    <InfoContainer
      header={t('maintenanceLong')}
      text={t(`${manufacturerCustomization[mid].name}.fdvText`)}
      button={<Button />}
    />
  );
};

export default Fdv;
