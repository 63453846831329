/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

import AppContext from '../../../context';
import manufacturerCustomization from '../../../helpers/manufacturerCustomization';
import Spinner from '../../spinner/spinner';

const GenerateDrawing = ({ vNumber }) => {
  const [id, setId] = useState(null);
  const [showImg, setShowImg] = useState(false);
  const [noImg, setNoImg] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const { t } = useTranslation();

  const mid = useContext(AppContext);

  useEffect(() => {
    setId(Cookies.get('id'));
  }, []);

  const generateImage = () => {
    setShowImg(true);
  };

  const handleError = () => {
    setNoImg(true);
  };

  const handleLoad = () => {
    setImgLoaded(true);
  };

  if (noImg) {
    return (
      <div>
        {t('noImg')}
      </div>
    );
  }

  if (!showImg) {
    return (
      <div style={{ cursor: 'pointer', marginTop: 10 }}>
        <div onClick={generateImage}>
          <div>
            <FontAwesomeIcon
              style={{ color: '#808080' }}
              className="text-muted"
              size="4x"
              icon={faImage}
            />
          </div>
          <div style={{ color: manufacturerCustomization[mid].colors.buttonBackground }}>
            {t('showImg')}
            <FontAwesomeIcon
              color={manufacturerCustomization[mid].colors.buttonBackground}
              style={{ marginLeft: 5 }}
              icon={faEye}
            />
          </div>
        </div>
      </div>
    );
  }

  if (!imgLoaded) {
    return (
      <div>
        <img
          onError={handleError}
          style={{ width: '100%', maxWidth: 200 }}
          src={`${process.env.REACT_APP_API_URL}/order-info/line/${vNumber}/image?id=${id}`}
          alt="product"
          onLoad={() => handleLoad()}
        />
        <Spinner
          size="lg"
          color="#808080"
        />
      </div>
    );
  }

  return (
    <div style={{ marginTop: 10 }}>
      <img
        onError={handleError}
        style={{ width: '100%', maxWidth: 200 }}
        src={`${process.env.REACT_APP_API_URL}/order-info/line/${vNumber}/image?id=${id}`}
        alt="product"
        onLoad={() => handleLoad()}
      />
    </div>

  );
};

export default GenerateDrawing;
