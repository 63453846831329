import axios from 'axios';

/**
* Request Wrapper with default success/error actions
*/
const request = async (options) => {
  // Set firebase functions url
  const apiUrl = process.env.REACT_APP_API_URL;

  /**
  * Create an Axios Client with defaults
  */

  const defaultValues = {};

  defaultValues.baseURL = apiUrl;
  // defaultValues.params = {};

  defaultValues.headers = {
    'Content-Type': 'application/json',
  };

  // Create axios client with custom config
  const client = axios.create(defaultValues);

  const onSuccess = (response) => response;

  const onError = (error) => {
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx

      console.group(
        `%cRequest error: ${error.response.config.url}`,
        'font-size: 0.85rem; font-weight: bold; padding-top: 10px;',
      );
      console.error('Method:', error.response.config.method.toUpperCase());
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
      console.groupCollapsed('Trace:');
      console.trace();
      console.groupEnd();
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.group('Error message:');
      console.error(error.message);
      console.groupEnd();
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
