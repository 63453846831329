import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const Spinner = ({ size, color }) => (
  <div
    className="w-100 p-4 d-flex justify-content-center align-items-center"
  >
    <FontAwesomeIcon
      icon={faSpinner}
      spin
      size={size}
      style={{
        color,
      }}
    />
  </div>
);

export default Spinner;
