import React, { useContext } from 'react';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import InfoContainer from '../infoContainer/infoContainer';
import CustomButton from '../spinnerButton/spinnerButton';
import AppContext from '../../context';
import manufacturerCustomization from '../../helpers/manufacturerCustomization';

const ComplaintForm = () => {
  const { t } = useTranslation();
  const mid = useContext(AppContext);

  const Button = () => (
    <a href={manufacturerCustomization[mid].complaintUrl}>
      <CustomButton
        icon={faExternalLinkAlt}
        width="200px"
      >
        {t('complaintBtn')}
      </CustomButton>
    </a>
  );

  return (
    <InfoContainer
      header={t('complaintHeader')}
      text={t(`${manufacturerCustomization[mid].name}.complaintText`)}
      button={<Button />}
    />
  );
};

export default ComplaintForm;
