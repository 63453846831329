import request from '../request';

const getReportUrl = (id, report) => request({
  /* report must be one of:
    'ORDERINFO_REPORT_MAINTENANCE'
    'ORDERINFO_REPORT_MEASURES'
    'ORDERINFO_REPORT_UVALUE'
  */
  url: `/order-info/report/${report}?id=${id}`,
  method: 'GET',
  headers: {},
});

export default getReportUrl;
